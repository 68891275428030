<template>
  <div nav-clear
       style="width: 100%; height:100%;position: fixed; left: 0px; right: 0px; top: 0px; bottom: 0px; background-color: white;z-index: 100000000;display: inline-block;  text-align: center; "
       class="congress-back-color" v-if="showPassword">
    <div style="margin-top:env(safe-area-inset-top) !important;">
      <img class="conference-image-progress" src="/assets/icon/icon.png">
      <div style="text-align: center;font-size: 0.9rem; color:white">
        <h4>Herzlich Willkommen zur Jahrestagung 2024</h4><br>
        <p class="p-2">Bitte geben Sie hier Ihr Passwort zur Freischaltung der App ein. Das Passwort haben Sie per E-Mail mit den letzten Informationen zum Kongress erhalten.</p>
      </div>
      <br>
      <div class="list">
        <!--label class="item item-input"> <span class="input-label">Username</span>
            <input type="text">
        </label-->

        <div class="item item-input-inset" style="border: none;">
          <label class="item-input-wrapper">
            <input type="text" placeholder="Password" v-model="pass">
          </label>
          <button class="btn bg-light text-dark ms-2" @click="loginClicked()">
            Login
          </button>
        </div>
        <div v-if="isError" class="item list-item list-item-title-1 item-text-wrap">
          {{error}}
        </div>
      </div>

    </div>
  </div>

</template>
<script>
import {defineComponent} from 'vue';
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: 'Splash',
  data() {
    return {
      pass: '',
      isError:false,
      error:'',
      show:false,
      password:'HWzJiB11'
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('splashscreen', ['showPassword']),
  },
  methods: {
    ...mapActions('banners', ['getForState']),
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapActions("files", [
      "getImage",
    ]),
    ...mapMutations("splashscreen", [
      "setShowPassword",
    ]),

    getStyle(splash) {
      return {
        'background-image': 'url(' + splash || '' + ')',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
        'background-size': 'contain'
      }
    },
    loginClicked () {
      if (this.pass === this.password) {
        localStorage.setItem('startPassword', this.pass);
        this.isError = false;
        this.setShowPassword(false)
      } else {
        this.error = 'Falsche Login Daten!';
        this.isError = true;
      }
    },


    async showPassword() {
      this.show = true
    },


    async initPassword() {
      let passwordFromStorage = localStorage.getItem('startPassword') || '';
      if (passwordFromStorage === this.password) {
        this.setShowPassword(false)

      } else {
        this.setShowPassword(true)
      }
    }
  },
  async created() {
    await this.initPassword()
  },
  watch: {
    showPassword: async function (newVal) {
      if (newVal == true) {
        console.log('showit', newVal)
        await this.initPassword()
        this.setShowPassword(true)

      } else {
        console.log('no newval')
      }
    },
  }
});
</script>
<style lang="scss" scoped>
.conference-image-progress{
  width:200px;
  height: 200px;
  margin-top: 20px;
}
</style>
